var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Vue from 'vue';
import { Component } from 'vue-property-decorator';
import { connect } from '@/overmind';
import Input from 'components/Input.vue';
let ResetPasswordContainer = class ResetPasswordContainer extends Vue {
    constructor() {
        super(...arguments);
        this.password = '';
        this.confirmPassword = '';
        this.loading = false;
        this.token = '';
        this.userName = '';
        this.userEmail = '';
    }
    showNotify(message, type) {
        this.$q.notify({
            message: message,
            type: type,
        });
    }
    mounted() {
        this.$gtag.pageview({ page_path: this.$route.path });
        let token = this.$route.params.token.split('=');
        this.token = token[1];
    }
    async confirm() {
        this.loading = true;
        // @ts-ignore TS2349: property inexistent
        await this.actions.recoverPassword({
            token: this.token,
            password: this.password,
            password_confirmation: this.password,
        });
        this.loading = false;
        if (
        // @ts-ignore TS2349: property inexistent
        this.state.statusResponse.type === 'recover-password' &&
            // @ts-ignore TS2349: property inexistent
            this.state.statusResponse.code === 201
        // @ts-ignore TS2349: property inexistent
        ) {
            this.password = '';
            this.confirmPassword = '';
            this.resetValidation();
            this.showNotify('Sua senha foi alterada com sucesso', 'success-password');
            // @ts-ignore TS2349: property inexistent
            this.$auth.logout();
        } // @ts-ignore TS2349: property inexistent
        else if (
        // @ts-ignore TS2349: property inexistent
        this.state.statusResponse.type === 'recover-password' &&
            // @ts-ignore TS2349: property inexistent
            this.state.statusResponse.code === 404) {
            this.showNotify('Seu código para alteração de senha expirou!', 'error-password');
        }
    }
    resetValidation() {
        setTimeout(() => {
            // @ts-ignore TS2349: property inexistent
            if (this.$refs.new) {
                // @ts-ignore TS2349: property inexistent
                this.$refs.new.resetValidation();
            }
            // @ts-ignore TS2349: property inexistent
            if (this.$refs.confirm) {
                // @ts-ignore TS2349: property inexistent
                this.$refs.confirm.resetValidation();
            }
        }, 200);
    }
    fieldsDisabled() {
        return !(this.isPasswordValid() && this.password == this.confirmPassword);
    }
    isPasswordValid() {
        return /^(?=.*\d)(?=.*[A-Z])(?=.*[!@#$%^&*])[A-Za-z\d!@#$%^&*]{12,}$/.test(this.password);
    }
};
ResetPasswordContainer = __decorate([
    Component(connect(({ state, effects, actions }) => ({
        state: state.users,
        actions: actions.users,
    }), {
        components: { Input },
    }))
], ResetPasswordContainer);
export default ResetPasswordContainer;
