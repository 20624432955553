var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"row justify-center items-center container--password",staticStyle:{"height":"100vh"}},[_c('div',{staticClass:"col-11 col-md-4"},[_c('div',{staticClass:"column"},[_c('h1',{staticClass:"text-center color-text-white"},[_vm._v("Redefinir senha")]),_c('div',{staticClass:"text-center q-mt-lg"},[_c('q-avatar',{attrs:{"size":"100px","color":"grey-4"}},[_c('img',{staticStyle:{"height":"60px","max-width":"60px"},attrs:{"src":"statics/images/logo-grey.png"}})])],1)]),_c('Input',{ref:"new",staticClass:"q-mt-md",attrs:{"data-cy":"page-reset-password-password-field","type":"password","rounded":"","outlined":"","required":"","placeholder":"Nova senha","disable":_vm.loading,"rules":[
        function (val) { return !!val || 'Campo é obrigatório'; },
        function (val) { return /^(?=.*\d)(?=.*[A-Z])(?=.*[!@#$%^&*])[A-Za-z\d!@#$%^&*]{12,}$/.test(
            val
          ) ||
          'Sua senha deve ter no mínimo 12 caracteres, entre eles um número, uma letra maiúscula e um caractere especial'; } ]},model:{value:(_vm.password),callback:function ($$v) {_vm.password=$$v},expression:"password"}}),_c('Input',{ref:"confirm",staticClass:"q-mt-lg",attrs:{"data-cy":"page-reset-password-password-confirm-field","type":"password","rounded":"","outlined":"","required":"","placeholder":"Confirmar senha","disable":_vm.loading,"rules":[
        function (val) { return !!val || 'Campo é obrigatório'; },
        function (val) { return val === _vm.password || 'As senhas não coincidem'; } ]},model:{value:(_vm.confirmPassword),callback:function ($$v) {_vm.confirmPassword=$$v},expression:"confirmPassword"}}),_c('q-btn',{staticClass:"text-capitalize q-mt-md",staticStyle:{"width":"100%"},attrs:{"data-cy":"page-reset-password-btn-confirm","color":"blue-7","text-color":"white","label":"Confirmar","rounded":"","unelevated":"","size":"lg","loading":_vm.loading,"disable":_vm.loading || _vm.fieldsDisabled()},on:{"click":_vm.confirm},scopedSlots:_vm._u([(_vm.loading)?{key:"loading",fn:function(){return [_c('q-spinner-hourglass',{staticClass:"on-left"}),_vm._v("Enviando...\n      ")]},proxy:true}:null],null,true)})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }